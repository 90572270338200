import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { PageEvent } from '@angular/material/paginator';
import { dreVisions } from '../component/page/company/reports/dre/interfaces/dre-visions';
// import { PageEvent } from '@angular/material';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  config = environment.makena ? {
    accountingVision: dreVisions.boxVision /*dre visão de caixa*/,
    pagination: {
      pageSize: 20,
      pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
      showFirstLastButtons: false
    }
  } : {
    accountingVision: dreVisions.boxVision /*dre visão de caixa*/,
    pagination: {
      pageSize: 5,
      pageSizeOptions: [5, 10, 15, 20],
      showFirstLastButtons: false
    }
  }

  constructor() {
    const configStr = localStorage.getItem('config');
    if(configStr){
      const config = JSON.parse(configStr);
      if (config) {
        this.config = config;
      }
    }
  }

  save() {
    let config = {}
    const configStr = localStorage.getItem('config');
    if(configStr){
      config = JSON.parse(configStr);
    }
    Object.assign(config, this.config);
    localStorage.setItem('config', JSON.stringify(config))
  }

  onChangePagination(ev: PageEvent){
    this.config.pagination.pageSize = ev.pageSize;
    this.save()
  }

}
